.markdown-content {
  color: #333;
  line-height: 1.6;
}

.markdown-content h1 {
  font-size: 1.5em;
  margin-bottom: 1em;
  color: #111;
  font-weight: 600;
}

.markdown-content h2 {
  font-size: 1.2em;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  color: #222;
  font-weight: 500;
}

.markdown-content p {
  margin-bottom: 1em;
  line-height: 1.5;
}

.markdown-content ul {
  list-style-type: disc;
  padding-left: 1.5em;
  margin-bottom: 1em;
}

.markdown-content li {
  margin-bottom: 0.5em;
  padding-left: 0.5em;
}

.markdown-content code {
  background-color: #f4f4f4;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.9em;
  font-family: 'Courier New', Courier, monospace;
  color: #333;
  border: 1px solid #e1e1e1;
}

.markdown-content strong {
  font-weight: 600;
}

.markdown-content em {
  font-style: italic;
}
