body {
  overflow: hidden;
  height: 100vh;
}

.product-matches-container {
  display: flex;
  gap: 12px;
  padding: 12px;
  height: 100vh;
  overflow-y: auto;
}

.source-product {
  flex: 0 0 300px;
  position: sticky;
  top: 12px;
  align-self: flex-start;
  padding: 12px;
  height: fit-content;
}

.matches-container {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 12px;
  height: 100%;
  overflow-y: scroll;
  align-content: flex-start;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.matches-container::-webkit-scrollbar {
  display: none;
}

.match-item {
  flex: 0 0 calc(25% - 9px);
  min-width: 240px;
  height: 380px;
  display: flex;
}

.product-card {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.product-card-image {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.product-card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Single scrollbar for matches container */
.product-matches-container::-webkit-scrollbar {
  width: 6px;
}

.product-matches-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.product-matches-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.product-matches-container::-webkit-scrollbar-thumb:hover {
  background: #666;
}

/* Ensure proper spacing for match scores */
.match-score {
  margin-top: 4px;
  text-align: center;
  color: #666;
}

/* Add hover effect to match cards */
.product-card:hover {
  transform: translateY(-2px);
  transition: transform 0.2s ease;
}

/* Style for highlighted cards */
.highlighted .nextui-card {
  border: 2px solid #0072F5;
  box-shadow: 0 4px 14px 0 rgba(0, 114, 245, 0.2);
}

@media (max-width: 1399px) {
  .match-item {
    flex: 0 0 calc(33.333% - 8px);
  }
}

@media (max-width: 1099px) {
  .match-item {
    flex: 0 0 calc(50% - 6px);
  }
}

@media (max-width: 799px) {
  .match-item {
    flex: 0 0 100%;
  }
}

.confirmation-modal {
  padding: 1.5rem;
  max-width: 400px;
  margin: 0 auto;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.modal-header {
  text-align: center;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 1rem;
}

.modal-body {
  text-align: center;
  padding: 1rem 0;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  border-top: 1px solid #eaeaea;
  padding-top: 1rem;
}
