.review-dashboard-product-matches-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  min-width: 0;
}

.reivew-dashboard-product-display-section {
  display: flex;
  padding: 0px 40px;
  margin-top: 70px;
  height: calc(100vh - 220px);
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.review-dashboard-action-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-section {
  position: fixed;
  z-index: 2;
  width: 100%;
  background-color: white;
  padding: 20px 30px;
  align-items: center;
  margin-left: 20px;
}

.filter-section .filters {
  display: flex;
  gap: 10px;
  align-items: center;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #eaeaea;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 2;
}

.pagination-controls button {
  min-width: 80px;
}

.review-dashboard-products-queue {
  height: calc(100vh - 185px);
  overflow-y: scroll;
}

.review-dashboard-products-queue::-webkit-scrollbar {
  display: none;
}

.reivew-dashboard-product-display-section {
  position: relative;
}

.sidebar {
  width: 350px;
  min-width: 350px;
  max-width: 350px;
  background: #f5f5f5;
  border-right: 1px solid #eaeaea;
  height: calc(100vh - 68px);
  overflow-y: auto;
  padding: 20px;
  position: relative;
}

.main-content {
  display: flex;
  width: 100%;
}

.product-list {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}

.product-list::-webkit-scrollbar {
  width: 6px;
}

.product-list::-webkit-scrollbar-track {
  background: transparent;
}

.product-list::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 3px;
}

.product-list-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;
}

.product-list-item.active {
  background-color: #f1f3f5;
}