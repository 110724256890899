.md-progress-bar-loading-wrapper {
  height: 750px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.md-progress-bar-loading-data-container {
  width: 50%;
}

.md-progress-bar-loading-data-container div {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}
