@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }

  .card {
    width: 100%;
  }

  .button {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  .image {
    max-width: 100%;
    height: auto;
  }
}