.product-tagger {
  display: flex;
  height: calc(100vh - 60px);
  padding: 20px;
  gap: 20px;
}

.source-product-section {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.source-product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background: #f5f5f5;
  border-radius: 8px;
}

.source-product-label {
  margin: 0;
  text-align: center;
}

.source-product-controls {
  padding: 15px;
  background: #f5f5f5;
  border-radius: 8px;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.target-products-section {
  flex: 1;
  overflow-y: auto;
}

.target-products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 15px;
  background: #f5f5f5;
  border-radius: 8px;
}

.target-product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background: white;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.target-product-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.target-product-card.selected {
  border: 2px solid #0072F5;
}

.match-score {
  margin: 0;
  font-size: 0.9em;
  color: #666;
}

.product-matches-container {
  padding-bottom: 80px;
  position: relative;
  min-height: 100vh;
}

.bottom-action-bar {
  position: absolute;
  bottom: -75px;
  left: 0;
  background: white;
  padding: 16px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: left 0.3s ease;
  width: 100%;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  max-width: 1200px;
  margin: 0 auto;
}

.dashboard-container {
  display: flex;
}

.product-list {
  margin-top: 20px;
}

.product-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 8px;
  transition: background-color 0.2s;
  position: relative;
}

.product-list-item:hover {
  background-color: #eaeaea;
}

.product-list-item.active {
  background-color: #e0e0e0;
}

.product-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 12px;
}

.product-info {
  flex: 1;
  overflow: hidden;
  margin-right: 70px;
}

.product-info p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-content {
  display: flex;
  flex: 1;
}

.product-matches-container {
  padding-bottom: 80px;
}

.shortcut-hint {
  font-size: 0.8em;
  opacity: 0.7;
  margin-left: 4px;
}

.review-status {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  text-transform: capitalize;
}

.review-status.approved {
  background-color: #4caf50;
  color: white;
}

.review-status.rejected {
  background-color: #f44336;
  color: white;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #eaeaea;
  position: fixed;
  bottom: 0;
  width: 300px;
  left: 65px;
  background-color: white;
  z-index: 2;
  transition: left 0.3s ease;
}

